import React from "react"
import { Link } from "gatsby"

const NotFound = () => (
  <>
    <div className="gradient grid grid-cols-1 h-screen">
      <div className="place-self-center">
        <div className="title is-3 is-size-2-desktop">Page Not Found</div>
        Oops, we couldn't find this page!
        <br />
        <Link
          className="block text-center shadow font-sans uppercase bg-danube text-white mt-5 w-full text-sm p-2 rounded-lg"
          to={"/"}
        >
          Home
        </Link>
      </div>
    </div>
  </>
)

export default NotFound
